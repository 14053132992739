/**
 * Vars populated from default values of https://minicss.org/flavors.
 *
 * TODO(minkezhang): Generate color palette with
 * https://color.adobe.com/create/image.
 *
 * TODO(minkezhang): Update using SASS vars instead.
 */

$secondary_border_color: #ddd;
$background_color: #f8f8f8;

body {
  margin: 0 auto;
  padding-bottom: 20vh;

  width: 960px;
  max-width: 100%;
}

div.byline, div#pagination, div#post-footer-content, div#share, div#footer-nav-wrap {
  margin: 12px 8px;
}

div#post-footer-content {
  text-align: center;
}

div#footer-nav-wrap {
  margin: 0 0 16px 0;

  ul#footer-nav {
    list-style-type: none;
    padding: 0;
    margin: 0 0;
  }
}

@media only screen and (max-width: 640px) {

  /* Maximize smaller screen estate */
  header.sticky {
    position: static;
  }

}

$post_footer_img_container_size: 80px;
$post_footer_img_container_padding: 6px;
$post_footer_img_container_offset: calc(($post_footer_img_container_size + 2 * ($post_footer_img_container_padding + 1px)) / 2);

div#post-footer {
  /* Offset entire div. */
  margin-top: $post_footer_img_container_offset + 10px;
  margin-bottom: -($post_footer_img_container_offset);

  width: 100%;

  /* Horizontal ruler bisecting the avatar. */
  border-color: $secondary_border_color;
  border-style: solid;
  border-width: 1px 0 0 0;

  /* Prominent author profile container. */
  div#post-footer-img-container {
    margin: 0 auto;

    /* Shift avatar over ruler. */
    position: relative;
    top: -($post_footer_img_container_offset);

    /* Total dimension is is (60px + 2 * (4px + 1px)) = 70px. */
    width: $post_footer_img_container_size;
    height: $post_footer_img_container_size;
    padding: $post_footer_img_container_padding;
    border-width: 1px;

    border-color: $secondary_border_color;
    border-style: solid;

    /* Make box opaque and render over parent. */
    background: $background_color;

    div#post-footer-img {
      /* background-image is auto-populated by the renderer. */

      /* Scale background image to the size of the div. */
      background-size: contain;

      /* Scale div size to parent dimensions. */
      height: 100%;
      width: 100%;
    }
  }
}
